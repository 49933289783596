import {
  Home,
  Test,
  NotFount404,
  AdminHome,
  Login,
  AdminLogin,
} from "../pages";
import DefaultHoc from "../components/DefaultHoc";
import { USER_TYPE_ADMIN, USER_TYPE_STUDENT } from "./Constants";

const routes = [
  {
    exact: true,
    path: "/admin",
    component: DefaultHoc(AdminLogin),
  },
  {
    path: "/test/:name",
    component: DefaultHoc(Test),
    userType: USER_TYPE_STUDENT,
  },
  {
    exact: true,
    path: "/ana-sayfa",
    component: DefaultHoc(Home),
    userType: USER_TYPE_STUDENT,
  },
  {
    exact: true,
    path: "/admin/ana-sayfa",
    component: DefaultHoc(AdminHome),
    userType: USER_TYPE_ADMIN,
  },
  {
    exact: true,
    path: "/",
    component: DefaultHoc(Login),
  },
  {
    path: "*",
    component: DefaultHoc(NotFount404),
  },
];

export default routes;
