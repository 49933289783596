import React, { useState } from "react";
import LoginView from "./LoginView";
import { Form } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../../reducer/app";
import { useHistory } from "react-router-dom";

const Login = (props) => {
  const [isRegistered, setIsRegistered] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    const url = isRegistered ? "/api/auth/login" : "/api/auth/register";

    setIsLoading(true);
    axios
      .post(url, values)
      .then((response) => {
        if (!response.data?.data?.user) {
          setIsRegistered(false);
        }

        if (response.data?.data?.user) {
          dispatch(login({ user: response.data.data.user }));
          history.push("/ana-sayfa");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoginView
      form={form}
      onFinish={onFinish}
      isRegistered={isRegistered}
      isLoading={isLoading}
    />
  );
};

export default Login;
