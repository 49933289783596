import axios from "axios";
import { logout } from "../reducer/app";
import message from "antd/lib/message";

const showMessageForResponse = (response) => {
  if (response.msg) {
    if (response.msgType) {
      message[response.msgType](response.msg);
    } else {
      message.info(response.msg);
    }
    return response;
  }
};

const setupAxios = (store) => {
  axios.interceptors.response.use(
    (response) => {
      if (
        response.data.error === "Login required" ||
        response.data.error === "Session timeout"
      ) {
        store.dispatch(logout());
      }
      showMessageForResponse(response.data);
      return response;
    },
    (error) => {
      if (error.response.status === 403) {
        store.dispatch(logout());
      }
      Promise.reject(error);
      showMessageForResponse(error.response);
      return error.response;
    }
  );
  axios.hasInterceptor = true;
};

export default setupAxios;
