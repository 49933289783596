import React from "react";
import { Layout, Typography, Tooltip, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { LogoutOutlined, HomeOutlined } from "@ant-design/icons/lib/icons";
import { logout } from "../reducer/app";

const CustomHeader = ({ title, canGoHome }) => {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  return (
    <Layout.Header id="header">
      <div id="logo">
        {canGoHome ? (
          <Link to="/ana-sayfa">
            <div id="goHome">
              <HomeOutlined style={{ fontSize: 24 }} />
              <span style={{ fontSize: 16 }}>Ana Sayfa</span>
            </div>
          </Link>
        ) : (
          <Image
            height={48}
            wrapperStyle={{ display: "flex" }}
            preview={false}
            src={process.env.PUBLIC_URL + "/assets/images/logo-header.png"}
          />
        )}
      </div>
      <div id="title">
        {title && <Typography.Text>{title}</Typography.Text>}
      </div>
      <div id="user">
        <div id="userInfo">
          <Typography.Text
            style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}
          >
            {app.user?.name} {app.user?.surName}
          </Typography.Text>
          <Typography.Text>{app.user?.email}</Typography.Text>
        </div>
        <Tooltip title="Çıkış yap">
          <LogoutOutlined
            onClick={onClickLogout}
            style={{ color: "#ffffff", fontSize: 24, cursor: "pointer" }}
          />
        </Tooltip>
      </div>
    </Layout.Header>
  );
};

export default CustomHeader;
