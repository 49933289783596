import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
};

export const logout = createAsyncThunk("app/logout", async () => {
  const response = await axios.get("/api/logout");
  return response.data;
});

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      localStorage.setItem("user", JSON.stringify(action.payload.user));
    },
  },
  extraReducers: {
    [logout.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.user = null;
        localStorage.clear();
      }
    },
  },
});

export const { login } = appSlice.actions;

export default appSlice.reducer;
