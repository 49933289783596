import React, { useEffect, useState } from "react";
import { Button, Card, Typography, Select, Input } from "antd";
import axios from "axios";

const { Option } = Select;

const TestConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [canShowResult, setCanShowResult] = useState(false);
  const [canShowResultInfo, setCanShowResultInfo] = useState("");

  const onClickSave = () => {
    setIsLoading(true);
    axios
      .post("/api/admin/setTestConfig", { canShowResult, canShowResultInfo })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/admin/testConfig")
      .then((response) => {
        if (response.data.success) {
          setCanShowResult(response.data.data.canShowResult.toString());
          setCanShowResultInfo(response.data.data.canShowResultInfo);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Card title="Test Ayarları">
      <div>
        <Typography.Text style={{ display: "block" }}>
          Öğrenci sonuçları görebilir mi
        </Typography.Text>
        <Select
          value={canShowResult}
          style={{ width: 400, marginBottom: 8 }}
          onChange={setCanShowResult}
        >
          <Option value="1">EVET</Option>
          <Option value="0">HAYIR</Option>
        </Select>
      </div>
      {canShowResult === "0" && (
        <div>
          <Typography.Text style={{ display: "block" }}>
            Sonuç metni
          </Typography.Text>
          <Input
            type="text"
            style={{ width: 400, marginBottom: 8 }}
            value={canShowResultInfo}
            onChange={(e) => setCanShowResultInfo(e.target.value)}
          />
        </div>
      )}
      <Button
        type="primary"
        onClick={onClickSave}
        loading={isLoading}
        disabled={isLoading}
      >
        Kaydet
      </Button>
    </Card>
  );
};

export default TestConfig;
