import React, { useState } from "react";
import {
  Col,
  Input,
  Layout,
  Row,
  Button,
  Select,
  Image,
  Card,
  Form,
  Typography,
  Checkbox,
  Modal,
} from "antd";
import { UserOutlined, MailOutlined, BookOutlined } from "@ant-design/icons";
import cities from "./../../util/cities.json";
import "./style.css";

const { Content, Footer } = Layout;

const LoginView = ({ form, onFinish, isRegistered, isLoading }) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const handleCancelModal = () => {
    setIsVisibleModal(false);
    setModalTitle("");
  };

  const kvkkTextClick = (e) => {
    e.preventDefault();
    fetch(`${process.env.PUBLIC_URL}/assets/templates/kvkk.html`)
      .then((data) => data.text())
      .then((response) => setModalContent(response))
      .finally(() => {
        setIsVisibleModal(true);
        setModalTitle("KVKK Metni");
      });
  };

  const commercialCommunicationClick = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.PUBLIC_URL}/assets/templates/commercialCommunication.html`
    )
      .then((data) => data.text())
      .then((response) => setModalContent(response))
      .finally(() => {
        setIsVisibleModal(true);
        setModalTitle("Ticari İletişim İzni");
      });
  };

  return (
    <Layout
      style={{
        backgroundSize: "contain",
        backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/login-background.jpg")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Content className="login-content">
        <Card bordered={false} className="login-form">
          <Col
            span={24}
            style={{ margin: 16, display: "flex", justifyContent: "center" }}
          >
            <Image
              preview={false}
              width={200}
              src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
            />
          </Col>
          <Col
            span={24}
            style={{ margin: 16, display: "flex", justifyContent: "center" }}
          >
            <Typography.Title level={4}>PDR TEST SİSTEMİ</Typography.Title>
          </Col>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Telefon numaranızı girmediniz.",
                    },
                    {
                      min: 10,
                      max: 10,
                      message:
                        "Telefon numarası başında 0 olmadan 10 karakter olmalı.",
                    },
                    {
                      required: true,
                      pattern: /^\d+$/g,
                      message: "Lütfen geçerli bir telefon numarası giriniz.",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    addonBefore="+90"
                    placeholder="Telefon numaranız"
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Lütfen geçerli bir E-Posta adresi giriniz",
                    },
                    {
                      required: true,
                      message: "E-Posta adresinizi girmediniz.",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="E-Posta adresi"
                    prefix={<MailOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </Col>
              {!isRegistered && (
                <>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="name"
                      rules={[
                        { required: true, message: "Adınızı girmediniz." },
                        { min: 2, message: "En az 2 karakter girmelisiniz." },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Öğrenci adı"
                        prefix={<UserOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="surName"
                      rules={[
                        { required: true, message: "Soyadıznı girmediniz." },
                        { min: 2, message: "En az 2 karakter girmelisiniz." },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Öğrenci soyadı"
                        prefix={<UserOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="school">
                      <Input
                        size="large"
                        placeholder="Okul"
                        prefix={<BookOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="cityCode"
                      rules={[{ required: true, message: "Şehir seçmediniz." }]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        options={cities}
                        showSearch
                        size="large"
                        placeholder="Şehir"
                        filterOption={(input, option) =>
                          option.label
                            .toLocaleLowerCase("tr-TR")
                            .indexOf(input.toLocaleLowerCase("tr-TR")) >= 0
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="gender"
                      rules={[
                        { required: true, message: "Cinsiyet seçmediniz." },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        size="large"
                        placeholder="Cinsiyet"
                      >
                        <Select.Option value="E">Erkek</Select.Option>
                        <Select.Option value="K">Kız</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} />
                </>
              )}

              <Col xs={24} md={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Kvkk metnini onaylamalısınız.",
                    },
                  ]}
                  name="kvkk"
                  valuePropName="checked"
                >
                  <Checkbox>
                    <Typography.Text onClick={kvkkTextClick} underline>
                      KVKK Metni
                    </Typography.Text>
                    'ni onaylıyorum.
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>
                    <Typography.Text
                      onClick={commercialCommunicationClick}
                      underline
                    >
                      Ticari İletişim İzni
                    </Typography.Text>
                    'ni onaylıyorum.
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    {isRegistered ? "Giriş Yap" : "Kayıt Ol"}
                  </Button>
                  {!isRegistered && (
                    <Button
                      size="large"
                      type="dashed"
                      htmlType="reset"
                      style={{ marginLeft: 14 }}
                      loading={isLoading}
                      disabled={isLoading}
                      onClick={() => form.resetFields()}
                    >
                      Sıfırla
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Footer style={{ background: "transparent" }}>
          PDR ©2021 Created by Akbim
        </Footer>
      </Content>

      <Modal
        width={768}
        title={modalTitle}
        visible={isVisibleModal}
        onCancel={handleCancelModal}
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Kapat
          </Button>,
        ]}
      >
        <div
          style={{ height: 400, overflow: "auto" }}
          dangerouslySetInnerHTML={{ __html: modalContent }}
        />
      </Modal>
    </Layout>
  );
};

export default LoginView;
