import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Card,
  Col,
  Image,
  Typography,
} from "antd";
import axios from "axios";
import { login } from "../../reducer/app";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const { Content, Footer } = Layout;

const AdminLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (values) => {
    setIsLoading(true);
    axios
      .post("/api/auth/login", values)
      .then((response) => {
        if (response?.data?.data?.user) {
          dispatch(login({ user: response.data.data.user }));
          history.push("/admin/ana-sayfa");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout>
      <Content className="login-content">
        <Card bordered={false} className="login-form">
          <Col
            span={24}
            style={{ margin: 16, display: "flex", justifyContent: "center" }}
          >
            <Image
              preview={false}
              width={200}
              src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
            />
          </Col>
          <Col
            span={24}
            style={{ margin: 16, display: "flex", justifyContent: "center" }}
          >
            <Typography.Title level={4}>
              PDR TEST SİSTEMİ ADMİN
            </Typography.Title>
          </Col>
          <Form
            name="basic"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Kullanıcı adını boş bırakmayınız !",
                },
              ]}
            >
              <Input placeholder="Kullanıcı Adı" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Şifreyi boş bırakmayınız !",
                },
              ]}
            >
              <Input.Password placeholder="Şifre" />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Giriş Yap
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Footer style={{ background: "transparent" }}>
          PDR ©2021 Created by Akbim
        </Footer>
      </Content>
    </Layout>
  );
};

export default AdminLogin;
