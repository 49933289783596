import StudentList from "./components/StudentList";
import TestReports from "./components/TestReports";
import TestConfig from "./components/TestConfig";

export default [
  {
    key: 1,
    component: <StudentList />,
  },
  {
    key: 2,
    component: <TestReports />,
  },
  {
    key: 3,
    component: <TestConfig />,
  },
];
