import React, { useEffect, useState } from "react";
import AdminConfig from "./AdminConfig";
import CustomHeader from "../../components/CustomHeader";
import { Layout, Menu } from "antd";
import {
  TeamOutlined,
  PieChartOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { Content, Sider } = Layout;

const AdminHome = () => {
  const [componentKey, setComponentKey] = useState(1);
  const [component, setComponent] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onClickMenu = (e) => {
    setComponentKey(parseInt(e.key, 10));
  };

  useEffect(() => {
    const adminComponent = AdminConfig.find((val) => val.key === componentKey);
    if (adminComponent) {
      setComponent(adminComponent.component);
    }
  }, [componentKey]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomHeader />
      <Layout className="site-layout">
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <Menu defaultSelectedKeys={["1"]} mode="inline" onClick={onClickMenu}>
            <Menu.Item key="1" icon={<TeamOutlined />}>
              Öğrenci Listesi
            </Menu.Item>
            <Menu.Item key="2" icon={<PieChartOutlined />}>
              Test Raporları
            </Menu.Item>
            <Menu.Item key="3" icon={<SettingOutlined />}>
              Test Ayarları
            </Menu.Item>
          </Menu>
        </Sider>
        <Content style={{ margin: 16 }}>{component}</Content>
      </Layout>
    </Layout>
  );
};

export default AdminHome;
