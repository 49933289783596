import React, { useEffect, useState } from "react";
import axios from "axios";
import { login, logout } from "../reducer/app";
import { useDispatch } from "react-redux";
import LoadingPage from "./LoadingPage";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import routes from "../util/Routes";

const DefaultHoc = (Component) => {
  return React.forwardRef(({ children, ...props }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const user = localStorage.getItem("user");
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      const publicRoutes = routes
        .filter((val) => !val.userType)
        .find((val) => val.path === location.pathname);
      if (!user && !publicRoutes) {
        setIsLoading(true);
        axios
          .get("/api/auth/checkSession")
          .then((response) => {
            if (response.data.success) {
              const user = response.data.data.user;
              const isUserRoute = routes.filter(
                (val) =>
                  val.userType === user.userType &&
                  matchPath(location.pathname, {
                    path: val.path,
                  })
              );
              if (isUserRoute) {
                dispatch(login({ user }));
              }
            } else {
              history.push("/");
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, [user]);

    if (isLoading) {
      return <LoadingPage />;
    }

    return (
      <Component ref={ref} {...props}>
        {children}
      </Component>
    );
  });
};

export default DefaultHoc;
