import React, { useEffect, useState } from "react";
import { Button, Card, Divider, Select, Table } from "antd";
import axios from "axios";
import Text from "antd/es/typography/Text";

const TestReports = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userTest, setUserTest] = useState([]);

  const onClickReport = (data) => {
    axios
      .get(`/api/admin/getReportLink?testId=${data.id}&userId=${selectedUser}`)
      .then((response) => {
        if (response.data.success) {
          window.open(response.data.data, "_blank");
        }
      });
  };

  const columns = [
    {
      title: "Test",
      dataIndex: "testName",
    },
    {
      title: "Raporla",
      dataIndex: "id",
      render: (text, record) => (
        <Button type="primary" onClick={() => onClickReport(record)}>
          Raporla
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/admin/userList")
      .then((response) => {
        if (response.data.success) {
          setOptions(
            response.data.data.map((val) => ({
              value: val.id,
              label: `${val.name} ${val.surName}`,
            }))
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setIsLoading(true);
      axios
        .get(`/api/admin/studentTestList?userId=${selectedUser}`)
        .then((response) => {
          if (response.data.success) {
            setUserTest(response.data.data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedUser]);

  return (
    <Card title="Test Sonuçları">
      <Select
        disabled={isLoading}
        showSearch
        value={selectedUser}
        onChange={setSelectedUser}
        style={{ width: 200 }}
        placeholder="Öğrenci Seçiniz"
        optionFilterProp="children"
        options={options}
        filterOption={(input, option) =>
          option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >=
          0
        }
      />
      <Divider />
      {userTest.length === 0 ? (
        <Text>Kullanıcının cevaplamış olduğu test bulunmamaktadır.</Text>
      ) : (
        <Table scroll={{ x: 400 }} dataSource={userTest} columns={columns} />
      )}
    </Card>
  );
};

export default TestReports;
